<template>
    <div>
        <!-- MAIN CONTENT ================================================= = -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <div class="card shadow-sm">
                    <div class="card-body py-3">
                        <div class="card-action card-action--with-title mb-0">
                            <div>
                                <a href="javascript:history.back()" class="btn btn-white">
                                    <i class="fe fe-arrow-left mr-2"></i>Kembali
                                </a>
                            </div>
                            <div class="card-action--title ml-3">
                                <div class="font-weight-bolder text-md">Edit Banner</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- FORM -->
                <form @submit.prevent="submitForm" enctype="multipart/form-data" class="mb-4">
                    <div class="card shadow-sm">
                        <div class="card-header">
                            <span class="font-weight-bolder">Gambar Banner</span>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label>Title<span class="text-primary">*</span></label>
                                <input type="text" v-model="form.title" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>URL</label>
                                <input type="url" v-model="form.url" class="form-control">
                            </div>
                            <div class="form-group mb-4 border-bottom pb-4 w-100">
                                <label>Upload Cover</label>
                                <div class="custom-file">
                                    <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event)" accept="image/x-png,image/jpg,image/jpeg">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>
                                <div v-bind:class="{'d-block': file_attachment != null, 'd-none': file_attachment == null}" class="mt-3" :id="'file-uploaded-section'">
                                    <div class="mb-3">
                                        <div class="file-uploaded-area" :id="'file-uploaded-area'" v-bind:style="{ 'background-image': 'url(' + form.cover + ')' }"></div>
                                    </div>
                                </div>

                                <div v-bind:class="{'d-block': file_attachment == null, 'd-none': file_attachment != null}" class="mt-3" :id="'file-uploaded-section-before'">
                                    <div class="mb-3">
                                        <div :id="'file-uploaded-area-temp'" class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(https://scolacdn.com/cms/default/default_banner.jpg)' }"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <progress v-if="uploadFile" :id="'upload-bar'" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                    <!-- Action -->
                    <div class="d-flex justify-content-end">
                        <a href="javascript:history.back()" class="btn text-muted">Batal & Kembali</a>
                        <button type="submit" class="btn btn-primary ml-2" :disabled="submitted"><i class="fe fe-save mr-2"></i>Simpan</button>
                    </div>
                    <div class="spacer"></div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                form: {
                    title: '',
                    url: '',
                    start_at: '',
                    expired_at: '',
                    status: 'publish',
                    cover: '',
                },
                uploadPercentage: 0,
                uploadFile: false,
                file_attachment: null,
                submitted: false,
                hasChooseFile: false
            }
        },
        created() {
            this.getData();
            // console.log('The id is: ' + this.$route.params.id);
        },
        methods: {
            previewFiles(e) {
                this.hasChooseFile = true;
                this.form.cover = '';
                const file = e.target.files[0];
                var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

                if (fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.cover = this.preview_image;
                    this.file_attachment = file;
                }
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + `admin/banner/detail?id=${this.$route.params.id}`)
                    .then(response => {
                        this.form.id = response.data.data[0].id;
                        this.form.title = response.data.data[0].title;
                        this.form.url = response.data.data[0].url;
                        this.form.start_at = response.data.data[0].start_at;
                        this.form.expired_at = response.data.data[0].expired_at;
                        this.form.cover = response.data.data[0].cover;
                        this.fetching = false;
                        if (this.form.cover != null && this.form.cover != '')
                            this.file_attachment = [];
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            saveData(file = null) {
                if (this.form.title == '' || this.form.description == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'inputan tidak boleh kosong',
                    })
                } else {
                    this.form.cover = file;
                    this.$http.post(this.baseUrl + 'admin/banner/save', this.form)
                        .then(() => {
                            this.submitted = false;
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Data News berhasil diubah',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.form.title = ''
                            this.form.slug = ''
                            this.$router.push({
                                name: 'banner.index'
                            })
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        }).finally(() => {
                            //Perform action in always
                        });
                }
            },
            submitForm() {
                this.submitted = true;
                if (this.hasChooseFile) {
                    this.uploadFile = true;

                    // const url_api = 'https://victoria-worker.edmission.id/';
                    const url_api = this.$apiconfig;
                    const callback = url_api + 'admin/banner/save';
                    let formData = new FormData();

                    formData.append('file[]', this.file_attachment);
                    formData.append('file_path', 'cover_program/');
                    formData.append('callback', callback);

                    this.$http.post(this.$api_upload_engine, formData, {
                            // headers: {
                            //     'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGhlbnRpY2F0aW9uLnNjb2xhLmlkIiwiYXVkIjoiaHR0cHM6Ly92aWN0b3JpYS13b3JrZXIuZWRtaXNzaW9uLmlkLyIsImp0aSI6IjRmMWcyM2ExMmFhIiwiaWF0IjoxNjMxODUxNDQ1Ljk3NjkyNCwiaWQiOjEsInVzZXJuYW1lIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW4iLCJyb2xlIjoiYWRtaW5pc3RyYXRvciIsImVtYWlsIjoiYWRtaW5AZ21haWwuY29tIiwicGhvbmUiOiIxMjMiLCJkb21haW4iOiJ2aWN0b3JpYS13b3JrZXIuZWRtaXNzaW9uLmlkIn0.tYoEt6RrKmQRq90wXLW7QjIPlXx_UZA2vWDAIZgZ6Pc'
                            // },
                            onUploadProgress: function(progressEvent) {
                                this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            }.bind(this)
                        })
                        .then(response => {
                            this.uploadPercentage = 0;
                            this.uploadFile = false;
                            const res = response.data;
                            const files = JSON.stringify(res.result);

                            if (res.status && res.result != "") {
                                this.saveData(files);
                            } else {
                                this.submitted = false;
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Gagal mengunggah file, coba lagi nanti!',
                                })
                            }
                        });
                } else {
                    this.saveData();
                }
            },
        },
    };
</script>